import axios from '@/plugins/axios'

export default {

    get_session_by_id(session_id) {
        return axios.rasaApi.get(`/session/${session_id}`)
    },

    get_sessions_pending_count(params) {
        return axios.rasaApi.get(`/session/pendings/count`, { params })
    },

    get_last_message(sender, service_id) {
        return axios.rasaApi.get(`/sender/${sender}/service/${service_id}/last_message`)
    },

    get_next_session(params) {
        return axios.rasaApi.get(`/session/next/session`, { params })
    },

    assign_to_me(id) {
        return axios.rasaApi.post(`/session/${id}/assign`)
    },

    get_my_sessions_by_status(params) {
        return axios.rasaApi.get(`/session/status/me`, { params })
    },

    get_sessions_by_params(params) {
        return axios.rasaApi.get(`/session`, { params })
    },

    get_sessions_by_search(params) {
        return axios.rasaApi.get(`/session/search`, { params })
    },

    patch_sessions_by_id(session_id, data) {
        return axios.rasaApi.patch(`/session/${session_id}`, data)
    },

    patch_session_source(session_id, data) {
        return axios.rasaApi.patch(`/session/${session_id}/source`, data)
    },

    patch_sessions_status(session_id, data) {
        return axios.rasaApi.patch(`/session/${session_id}/status`, data)
    },
    post_vacation(data) {
        return axios.rasaApi.post(`/session/vacation`, data)
    },
    get_customer_deals(customer_id) {
        const params = {
            "crm_ref": customer_id
        }
        return axios.rasaApi.get(`/session/deals`, { params })
    },
    get_count_by_service(service_id) {
        const params = {
            "service_id": service_id
        }
        return axios.rasaApi.get(`/session/count`, { params })
    },
    check_expiration(session_id) {
        return axios.rasaApi.get(`/session/${session_id}/check_expiration`)
    },
}